import React from "react"
import { graphql } from "gatsby"
import PostList from "../components/postlist"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = 'Tools';
    const posts = data.allMarkdownRemark.edges
    const introduction = (
      <span>
        <p>
          During our daily work we try to put some of our ideas into tools and share them with the community. There were numerous tools previously 
          which we could not maintain any longer so we removed them from our list when codingfreaks was re-reamped in the beginning of 2020. We will
          work on additional tools and post them here.
        </p>
      </span>
    );

    return (
        <PostList posts={posts} siteTitle={siteTitle} introduction={introduction} />
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(filter: {frontmatter: {postType: {eq: "Tools"}}}, sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            postType
            language
          }
        }
      }
    }
  }
`

